export function hasErrors (fieldsError) {
  let yes = false
  Object.keys(fieldsError).some((value) => {
    if (fieldsError[value] !== undefined) {
      fieldsError[value].some((someValue) => {
        if (someValue !== undefined && (typeof someValue === 'string' || Array.isArray(someValue))) {
          yes = true
          return true
        }
      })
    }
  })
  return yes
}

export function hasErrorsExcept (fieldsError, except) {
  let yes = false
  Object.keys(fieldsError).filter(x => !except.includes(x)).some((value) => {
    if (fieldsError[value] !== undefined) {
      fieldsError[value].some((someValue) => {
        if (someValue !== undefined && (typeof someValue === 'string' || Array.isArray(someValue))) {
          yes = true
          return true
        }
      })
    }
  })
  return yes
}
