<template>
  <div :class="$style['action-tools']">
    <router-link :class="$style.links" v-for="(option, index) in routerOptions" :to="option.to" :key="index">
      <a-tooltip>
        <template slot='title'>
          {{ option.title }}
        </template>
        <a-icon :theme="option.theme === undefined ? 'twoTone' : option.theme" :type="option.icon"></a-icon>
      </a-tooltip>
    </router-link>
    <a-dropdown v-if="handleRefresh" :class="$style.links" :placement="'bottomRight'" :trigger="['click','hover']">
      <a-icon type="setting" theme="twoTone" class="ant-dropdown-link"></a-icon>
      <a-menu slot="overlay">
        <a-menu-item @click="handleRefresh" key="0">
          <a-icon type="reload"></a-icon> Aktualizovat
        </a-menu-item>
        <a-menu-divider v-if="menuItems && Array.isArray(menuItems) && menuItems.length !== 0"></a-menu-divider>
        <a-menu-item v-for="(item, index) in menuItems" @click="item.func" :key="index + 1">
          <a-icon v-if="item.icon" :type="item.icon"></a-icon>
          {{ item.title }}
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
export default {
  name: 'action-tools',
  props: {
    handleRefresh: Function,
    routerOptions: Array,
    menuItems: Array,
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e)
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
